import React, { Fragment } from "react";
import './Home.css';
import './Media.css';
import map from './img/Aqua-Technics-AUS-map.webp';


function Banner() {
  return (
    <>



      <div class="mainBanner desk"><img src={map} usemap="#image-map" class="sliderimg" alt="Fibreglass Pools" />
        <map name="image-map" >
          <area target="" alt="Aqua Technics WA" title="Aqua Technics WA" href="/index.php/aquatechnics/wa/" coords="632,76,637,291,612,297,602,301,596,313,592,320,586,323,576,323,565,322,554,329,545,336,533,343,523,344,507,341,499,335,499,316,497,296,487,276,480,260,468,245,464,235,473,226,465,214,464,194,465,180,493,159,515,155,537,149,556,130,556,115,563,98,573,100,585,94,580,88,586,79,608,64,619,67" shape="poly" />
          <area target="" alt="Aqua Technics SA" title="Aqua Technics SA" href="/eastern-states/aquatechnics/sa/" coords="639,291,638,219,769,220,756,374,743,338,729,334,734,320,721,332,731,304,713,321,705,333,693,310,687,297,663,291,648,289" shape="poly" />
          <area target="" alt="Aqua Technics QLD" title="Aqua Technics QLD" href="/eastern-states/aquatechnics/qld/" coords="746,93,740,214,766,214,773,217,768,250,807,257,830,257,847,259,861,256,873,260,881,267,890,260,899,262,909,261,909,233,901,213,890,197,889,184,879,183,877,175,867,157,859,144,847,136,842,123,836,106,829,92,829,77,819,69,810,57,801,31,799,22,792,31,792,52,790,73,786,86,776,97,765,103,810,127" shape="poly" />
          <area target="" alt="Aqua Technics NSW" title="Aqua Technics NSW" href="/eastern-states/aquatechnics/nsw/" coords="775,260,830,261,856,265,872,263,885,273,897,269,909,264,911,273,905,286,901,298,893,308,883,316,879,326,862,329,835,325,818,327,803,333,794,342,787,334,778,327,771,320" shape="poly" />
          <area target="" alt="Aqua Technics ACT" title="Aqua Technics ACT" href="/eastern-states/aquatechnics/act/" coords="791,344,808,330,836,325,858,328,874,329,873,337,862,348,856,362,853,372,842,365,838,356,825,352,812,351,802,351" shape="poly" />
          <area target="" alt="Aqua Technics VIC" title="Aqua Technics VIC" href="/eastern-states/aquatechnics/victoria/" coords="766,323,763,374,774,382,785,382,795,375,801,374,804,382,811,389,818,387,827,380,839,378,849,374,837,367,834,357,818,358,808,355,802,355,791,348,785,340,775,334" shape="poly" />
        </map>
      </div>

      <div className="mainBanner mob"><h2>Our <em class="emph">promise</em> to you - <br />there simply is no <em class="emph">better</em> swimming <em class="emph">pool</em></h2>
        <div className="mob-menu-container">
          <div className="mmmenu-col1"><a href="/aquatechnics/wa/">WA</a></div>
          <div className="mmmenu-col2"><a href="/eastern-states/aquatechnics/sa/">SA</a></div>
          <div className="mmmenu-col3"><a href="/eastern-states/aquatechnics/qld/">QLD</a></div>
          <div className="mmmenu-col4"><a href="/eastern-states/aquatechnics/nsw/">NSW</a></div>
          <div className="mmmenu-col5"><a href="/eastern-states/aquatechnics/act/">ACT</a></div>
          <div className="mmmenu-col6"><a href="/eastern-states/aquatechnics/victoria/">VIC</a></div>
        </div>
      </div>
    </>
  )
}

export default Banner;



