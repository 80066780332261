import React from "react";
import logo from './img/ATP_Logo2024-BlueBox.webp';
import Banner from "./Main_Banner";
import BuiltBan from './img/ATP_Built-like-no-otherWHITE.webp'
import BuiltBanM from './img/ATP_Built-like-no-otherWHITE-mob.webp'
import './Media.css';

function Header() {
    return (
        <frameElement>
            <div className="header-container">
                <div className="header-col-left"><img src={logo} alt="Aqua Technics - Fibreglass Swimming Pools Perth" /></div>
                <div className="header-col-right desk"><img src={BuiltBan} alt="Aqua Technics - Fibreglass Swimming Pools Perth" /></div>
                <div className="header-col-right mob"><img src={BuiltBanM} alt="Aqua Technics - Fibreglass Swimming Pools Perth" /></div>
            </div>
            <div><Banner /></div>
        </frameElement>
    )
}

export default Header;



